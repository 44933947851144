import { localeForMarket } from '@bemlo/utils'
import { Market } from '@bemlo/enums'

import { MarketFeature } from '../enums'
import { MarketConfig } from '../types'
import translations from '../../translations/locales/nb'

import { baseMarketMetadata } from './base-meta'
import { Country, Currency } from '../../generated/graphql'

// Cannot use getTranslation here, becuase this is used outside the scope of Webpack
const t = translations.meta

export const marketConfig: MarketConfig = {
  market: Market.NO,
  locale: localeForMarket(Market.NO),
  localeName: 'nb-NO',
  country: {
    currency: Currency.NOK,
    name: 'Norway',
    value: Country.NORWAY,
    wikidataCode: 'Q20',
  },
  features: [
    MarketFeature.ABOUT_US,
    MarketFeature.BLOG,
    MarketFeature.COMPANY,
    MarketFeature.FAQ,
    MarketFeature.PROFILE,
    MarketFeature.JOBS,
  ],
  meta: {
    ...baseMarketMetadata,
    companyNamePossessive: t.company_name_possessive,
    siteDescription: t.site_description,
    siteKeywords: t.site_keywords,
    siteNamePossessive: t.site_name_possessive,
    email: 'info@bemlo.no',
    emailDomain: 'bemlo.no',
    siteCareerUrl: 'https://career.bemlo.se',
    siteDomain: 'bemlo.no',
    siteLocale: 'nb_NO',
    siteUrl: 'https://www.bemlo.no',
  },
  segmentProdKey: '2EyLBDHtRB4jInLmB2HeaisLSmZLcGmE',
  reviewsFrom: [Market.NO],
}
